import { FC, useEffect } from 'react'

import { useState } from 'react'

import { usePaymentDataContext } from '@/shared/contexts/payment-data-context'

import { getFrameScr } from './utils/get-frame-src'

import styles from './main-page.module.scss'
import { Layout } from '@/widgets'
import { OrderInfo } from '@/entities'
import { useListenerEvents } from '@/shared/hooks/useListenerEvents'
import clsx from 'clsx'
import { ReactComponent as ArrowLeft } from '@/shared/icons/arrow-left.svg'
import { useLanguage } from '@/shared/contexts/language-context'

export const MainPage: FC = () => {
  const { showFullScreen, frameHeight } = useListenerEvents()
  const { getField } = useLanguage()

  const { orderInfo, currentMethod, frameToken } = usePaymentDataContext()

  const [frameSrc, setFrameSrc] = useState(
    getFrameScr({ method: currentMethod, token: frameToken }),
  )

  useEffect(() => {
    setFrameSrc(getFrameScr({ method: currentMethod, token: frameToken }))
  }, [currentMethod, frameSrc])

  return (
    <>
      <div
        className={clsx(styles['main-page-order'], {
          [styles['main-page-order-showFullScreen']]: showFullScreen,
        })}
      >
        <Layout leftButton={<ArrowLeft />} title={getField('payment_title')}>
          {orderInfo && <OrderInfo orderInfo={orderInfo} />}
        </Layout>
      </div>
      <div
        className={clsx(styles['iframe-container'], {
          [styles['iframe-container-showFullScreen']]: showFullScreen,
        })}
      >
        {frameSrc ? (
          <iframe
            height={frameHeight ? frameHeight : '500'}
            className={styles.iframe}
            src={frameSrc}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
