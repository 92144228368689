import type { PaymentMethodInfoPublic } from '@/shared/api/swagger'
import { SearchParams } from '@/shared/constants/search-params'

export const getFrameScr = ({
  method,
  token,
}: {
  method: PaymentMethodInfoPublic | null
  token: null | string
}) => {
  if (method && token) {
    return (
      method.gate_url +
      '?' +
      new URLSearchParams({
        [SearchParams.OpenFromHub]: SearchParams.OpenFromHub,
        [SearchParams.Token]: token,
      }).toString()
    )
  }

  return ''
}
