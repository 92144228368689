/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * GateTokenRequest
 * Request for a token issued by the Payment Hub for payment initiation
 * at gate.
 */
export interface GateTokenRequest {
  /** Payment System Id */
  payment_system_id: number
  /** Payment Method Id */
  payment_method_id: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** HealthcheckCallSchema */
export interface HealthcheckCallSchema {
  /** Passed */
  passed: boolean
  /**
   * Elapsed
   * @format duration
   */
  elapsed: string
}

/** HealthcheckResponseSchema */
export interface HealthcheckResponseSchema {
  /**
   * Healthy
   * @default true
   */
  healthy?: boolean
  /** Checks */
  checks?: Record<string, HealthcheckCallSchema>
}

/** LocalizationDataStored */
export interface LocalizationDataStored {
  /** Language Code */
  language_code: string
  /** Data */
  data: object
  /** Id */
  id: number
}

/**
 * LocalizationUpdateRequest
 * Contract for localization endpoint.
 */
export interface LocalizationUpdateRequest {
  /** Ru */
  ru?: object | null
  /** En */
  en?: object | null
  [key: string]: any
}

/**
 * MerchantTokenInfo
 * Limited information about the merchant token.
 */
export interface MerchantTokenInfo {
  /** Language Code */
  language_code: string
}

/** MerchantTokenRequest */
export interface MerchantTokenRequest {
  /** Order Id */
  order_id: string
  /** Region Code */
  region_code: string
  /** Merchant Id */
  merchant_id: number
  /** Language Code */
  language_code: string
  /** User Id */
  user_id: string
  /** User Email */
  user_email?: string | null
  /**
   * Amount Value
   * @min 0
   */
  amount_value: number
  /** Amount Currency */
  amount_currency: string
}

/** OrderInfo */
export interface OrderInfo {
  /** Id */
  id: string
  /** Total */
  total: object
  /** Positions */
  positions: any[]
}

/** PaymentMethod */
export interface PaymentMethod {
  /** Code */
  code: string
  /** Id */
  id: number
}

/** PaymentMethodInfoPublic */
export interface PaymentMethodInfoPublic {
  /** Gate Url */
  gate_url: string
  /** System Id */
  system_id: number
  method: PaymentMethod
  /**
   * Was Used Last
   * @default false
   */
  was_used_last?: boolean
}

/**
 * PingResponseSchema
 * @default "pong"
 */
export type PingResponseSchema = string

/** RootResponseSchema */
export interface RootResponseSchema {
  /** Name */
  name: string
  /** Version */
  version?: string | null
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '/api',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title ph-backend
 * @version 0.1.2
 * @baseUrl /api
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  payment = {
    /**
     * @description Get part of the merchant token payload.
     *
     * @tags payment
     * @name GetTokenInfoPaymentTokenInfoGet
     * @summary Get Token Info
     * @request GET:/payment/token-info
     */
    getTokenInfoPaymentTokenInfoGet: (params: RequestParams = {}) =>
      this.request<MerchantTokenInfo, void | HTTPValidationError>({
        path: `/payment/token-info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name GetOrderInfoPaymentOrderInfoGet
     * @summary Get Order Info
     * @request GET:/payment/order-info
     */
    getOrderInfoPaymentOrderInfoGet: (params: RequestParams = {}) =>
      this.request<OrderInfo, void | HTTPValidationError>({
        path: `/payment/order-info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name GetPaymentMethodsPaymentMethodsGet
     * @summary Get Payment Methods
     * @request GET:/payment/methods
     */
    getPaymentMethodsPaymentMethodsGet: (params: RequestParams = {}) =>
      this.request<PaymentMethodInfoPublic[], void | HTTPValidationError>({
        path: `/payment/methods`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name CreateTokenForGatePaymentTokensPost
     * @summary Create Token For Gate
     * @request POST:/payment/tokens
     */
    createTokenForGatePaymentTokensPost: (
      data: GateTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<PingResponseSchema, void | HTTPValidationError>({
        path: `/payment/tokens`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get localization json for the specified language.
     *
     * @tags payment
     * @name GetLocalizationPaymentLocalizationLanguageCodeGet
     * @summary Get Localization
     * @request GET:/payment/localization/{language_code}
     */
    getLocalizationPaymentLocalizationLanguageCodeGet: (
      languageCode: string,
      params: RequestParams = {},
    ) =>
      this.request<LocalizationDataStored, void | HTTPValidationError>({
        path: `/payment/localization/${languageCode}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  management = {
    /**
     * @description Update acquirer selection (payment routing) rules.
     *
     * @tags management
     * @name PutAcquirerRulesManagementAcquirerRulesRegionCodePut
     * @summary Put Acquirer Rules
     * @request PUT:/management/acquirer-rules/{region_code}
     */
    putAcquirerRulesManagementAcquirerRulesRegionCodePut: (
      regionCode: string,
      data: object,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/management/acquirer-rules/${regionCode}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update localization data.
     *
     * @tags management
     * @name PutLocalizationManagementLocalizationPut
     * @summary Put Localization
     * @request PUT:/management/localization
     */
    putLocalizationManagementLocalizationPut: (
      data: LocalizationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/management/localization`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  app = {
    /**
     * No description
     *
     * @tags Health Check
     * @name LambdaAppInfoGet
     * @summary General service information
     * @request GET:/app/info
     */
    lambdaAppInfoGet: (params: RequestParams = {}) =>
      this.request<RootResponseSchema, any>({
        path: `/app/info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health Check
     * @name LambdaAppPingGet
     * @summary "Pong" response
     * @request GET:/app/ping
     */
    lambdaAppPingGet: (params: RequestParams = {}) =>
      this.request<PingResponseSchema, any>({
        path: `/app/ping`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description partial(func, *args, **keywords) - new function with partial application of the given arguments and keywords.
     *
     * @tags Health Check
     * @name PartialAppHealthGet
     * @summary Call health checks
     * @request GET:/app/health
     */
    partialAppHealthGet: (params: RequestParams = {}) =>
      this.request<HealthcheckResponseSchema, any>({
        path: `/app/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  dev = {
    /**
     * @description Generate a test token imitating the ones issued by the marchant.
     *
     * @tags dev
     * @name CreateMerchantTokenDevMerchantTokensPost
     * @summary Create Merchant Token
     * @request POST:/dev/merchant-tokens
     */
    createMerchantTokenDevMerchantTokensPost: (
      data: MerchantTokenRequest,
      query?: {
        /**
         * Expiration
         * Example: 2024-08-06 09:26:57+00:00, default - in one day
         * @format date-time
         */
        expiration?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<PingResponseSchema, HTTPValidationError>({
        path: `/dev/merchant-tokens`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
}
