import { useNavigate } from 'react-router'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { useEffect, useState } from 'react'
import {
  MAIN_PAGE,
  METHODS_PAGE,
  PAYMENT_FAILED_PAGE,
  PAYMENT_INTERRUPT_PAGE,
  PAYMENT_SUCCESS_PAGE,
} from '@/shared/constants/routes'
import { ParentEvents } from '@/shared/interfaces/parent-events.types'

interface Handlers {
  success_page_open: () => void
  success_back_button_click: () => void
  interrupt_page_open: () => void
  interrupt_retry_button_click: () => void
  failed_page_open: () => void
  failed_retry_button_click: () => void
  show_iframe_full_screen: () => void
  show_iframe_small_screen: () => void
  methods_page_open: () => void
  set_height: (height: string) => void
}

interface MessageData {
  event: keyof Handlers
  data?: { height: string }
}

export const useListenerEvents = () => {
  const navigate = useNavigate()
  const { params } = useSearchParams()
  const [showFullScreen, setShowFullScreen] = useState(false)
  const [frameHeight, setFrameHeight] = useState('')

  const handlers: Handlers = {
    success_page_open: () => {
      alert('success_page_open')
      // navigate({
      //   pathname: PAYMENT_SUCCESS_PAGE,
      //   search: new URLSearchParams(params).toString(),
      // })
    },
    success_back_button_click: () => {
      alert('success_back_button_click')
      navigate({
        pathname: MAIN_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },

    interrupt_page_open: () => {
      alert('interrupt_page_open')
      // navigate({
      //   pathname: PAYMENT_INTERRUPT_PAGE,
      //   search: new URLSearchParams(params).toString(),
      // })
    },
    interrupt_retry_button_click: () => {
      alert('interrupt_retry_button_click')
    },

    failed_page_open: () => {
      alert('failed_page_open')
      // navigate({
      //   pathname: PAYMENT_FAILED_PAGE,
      //   search: new URLSearchParams(params).toString(),
      // })
    },
    failed_retry_button_click: () => {
      alert('failed_retry_button_click')
    },
    show_iframe_full_screen: () => {
      setShowFullScreen(true)
    },
    show_iframe_small_screen: () => {
      setShowFullScreen(false)
    },
    methods_page_open: () => {
      navigate({
        pathname: METHODS_PAGE,
        search: new URLSearchParams(params).toString(),
      })
    },
    set_height: (height: string) => {
      setFrameHeight(height)
    },
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        if (event.origin === window.location.origin) {
          return
        }
        const data = event.data as MessageData
        if (handlers[data.event]) {
          if (data.event === ParentEvents.SetHeight) {
            data.data?.height && handlers[data.event](data?.data?.height)
            return
          }
          handlers[data.event]()
        }
      } catch (error) {
        console.error('event error:', error)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return { showFullScreen, setShowFullScreen, frameHeight }
}
