import type { FC } from 'react'

import { useCallback, useState } from 'react'

import { usePaymentDataContext } from '@/shared/contexts/payment-data-context'
import { Button } from '@/shared/components/button'
import { PaymentMethodInfoPublic } from '@/shared/api/swagger'
import { useNavigate } from 'react-router'
import { useSearchParams } from '@/shared/contexts/search-params-context'
import { MAIN_PAGE } from '@/shared/constants/routes'
import styles from './methods-page.module.scss'
import { Layout } from '@/widgets'
import { ReactComponent as CloseIcon } from '@/shared/icons/close-icon.svg'
import { useLanguage } from '@/shared/contexts/language-context'

export const MethodsPage: FC = () => {
  const navigate = useNavigate()
  const { getField } = useLanguage()

  const { params } = useSearchParams()
  const { methods, selectMethod } = usePaymentDataContext()

  const [isDisabled, setDisabled] = useState(false)

  const onSelectMethod = useCallback(
    (method: PaymentMethodInfoPublic) => {
      setDisabled(true)

      selectMethod(method)
        .then(() => {
          navigate({
            pathname: MAIN_PAGE,
            search: new URLSearchParams(params).toString(),
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    },
    [params],
  )

  const toMainPage = useCallback(() => {
    navigate({
      pathname: MAIN_PAGE,
      search: new URLSearchParams(params).toString(),
    })
  }, [params])

  return (
    <Layout
      title={getField('methods_page_title')}
      rightButton={
        <CloseIcon
          style={{
            cursor: 'pointer',
            margin: '-10px',
            padding: '10px',
          }}
          onClick={toMainPage}
        />
      }
    >
      <div className={styles['container']}>
        {methods.map((method) => (
          <Button
            fullwidth
            key={method.method.id}
            variant="outline"
            disabled={isDisabled}
            onClick={() => onSelectMethod(method)}
            className={'method'}
          >
            {method.method.code}
          </Button>
        ))}
      </div>
    </Layout>
  )
}
